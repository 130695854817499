:root {
    --primary-color: #e04b4b;
    --text-color: rgb(140, 141, 151);
}
p {
    color: rgb(140, 141, 151);
}

.big-heading {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    font-family: 'DM Sans', sans-serif;
}

.light-text-bottom {}

.primary-btn {
    background-color: var(--primary-color) !important;
    padding: 15px 25px;
    font-family: 'DM Sans', sans-serif;
    text-transform: uppercase;
    font-size: 0.8em;
    color: white;
    font-weight: 700;
    letter-spacing: 2px;
}

.custom-select {
    margin: 0px auto;
}

.text-primary {
    color: var(--primary-color) !important;
}

.bg-primary {
    background-color: var(--primary-color) !important;
    color: white;
}
.text-grey {
    color: rgb(140, 141, 151);
}
.secondary-border-btn {
    display: flex;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 10px 20px;
    color: var(--primary-color);
    border: 2px solid var(--primary-color) !important;
}

@media screen and (max-width: 550px) {
    .circle-img {
        max-height: 320px;
    }
}