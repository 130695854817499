.login-container {
    background-color: #f5f7fa;
    width: 100%;

    height: 80vh;
    display: grid;
    place-items: center;
}

.login-wrapper {
    background-color: white;
    min-width: 320px;
    max-width: 320px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}