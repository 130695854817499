.edit-pass-container {
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {

    .edit-pass-container {


        padding: 4px;

    }
}