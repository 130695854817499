.grid-image-container .parent {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 3px;
    grid-row-gap: 3px;
}

.grid-image-container .div1,
.grid-image-container .div2,
.grid-image-container .div3,
.grid-image-container .div4,
.grid-image-container .div5 {
    width: 100%;
    max-height: 250px;
    cursor: pointer;
}

.grid-image-container .div1 {
    grid-area: 1 / 1 / 3 / 3;
    overflow: hidden;
}

.grid-image-container .div2 {
    grid-area: 1 / 3 / 3 / 7;
    overflow: hidden;
}

.grid-image-container .div3 {
    grid-area: 1 / 7 / 3 / 9;
    overflow: hidden;
}

.grid-image-container .div4 {
    grid-area: 3 / 1 / 5 / 5;
    overflow: hidden;
}

.grid-image-container .div5 {
    grid-area: 3 / 5 / 5 / 9;
    overflow: hidden;
}

.overlay-wrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.overlay-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.grid-image-container .parent div .overlay-wrapper {
    border-radius: 20px;
    overflow: hidden;
}

.overlay-content-container {
    position: absolute;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    background-color: transparent;
    padding: 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    background-position: center;
    background-repeat: no-repeat;
}

.country-name {
    position: absolute;
    bottom: 20px;
    font-size: 1.2em;
    font-weight: 700;
}

.grid-image-container .div1 img,
.grid-image-container .div2 img,
.grid-image-container .div3 img,
.grid-image-container .div4 img,
.grid-image-container .div5 img {
    transition: all 2s ease-in;
}

.grid-image-container .div1:hover img,
.grid-image-container .div2:hover img,
.grid-image-container .div3:hover img,
.grid-image-container .div4:hover img,
.grid-image-container .div5:hover img {
    transform: scale(1.2);
}
.destination-small-grid {
    width: 295px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
@keyframes imgZoom {
    0% {
        background-position: center top;
        background-size: 100%;
    }

    100% {
        background-position: center top;
        background-size: 150%;
    }
}

@media screen and (max-width: 768px) {
    .grid-image-container .parent {
        display: flex;
        flex-wrap: wrap;
    }

    .grid-image-container .div1,
    .grid-image-container .div2,
    .grid-image-container .div3,
    .grid-image-container .div4,
    .grid-image-container .div5 {
        width: 100%;
        margin: 10px auto;
    }
}