.register-container {
    background-color: #f5f7fa;
    width: 100%;
    display: grid;
    place-items: center;
    padding: 20px;
}

.register-wrapper {
    background-color: white;
    /* min-width: 600px; */
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.error-display-container {
    background-color: rgb(254, 194, 194);
    color: rgb(139, 47, 47);
    border: 1px solid rgb(139, 47, 47);
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
}


.success-display-container {
    background-color: rgb(191, 238, 191);
    color: green;
    border: 1px solid green;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
}
