.custom-select {
    width: 270px;
    height: 60px;
    background-color: white;
    cursor: pointer;

}

.people-close-click {
    display: flex;
    flex-direction: column;
    overflow: visible;
}
.select-icon-container span {
    font-size: 40px;
    margin: auto;
    color: rgb(206, 73, 73)
}

.custom-select-option-container {
    position: absolute;
        max-width: 270px;
        width: 100%;
        height: 200px;
        bottom: 12px;
    overflow-y: scroll;
    z-index: 3;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}


.custom-select-option-container ul {
    height: 100%;
    background-color: white;
}
.custom-select-option-container .list-group-item {
    padding: 0 !important;
    border: none !important;
    font-size: 14px;
    height: 40px;
    line-height: 22px;
    padding-left: 10px !important;
}

.custom-select-option-container li {
    cursor: pointer;
}

.custom-select-option-container li:hover {
    background-color: #cccccc;
}

.arrow-container span {
    font-size: 16px;
    transition: transform 0.3s;
}
.people-close-click.is-open .arrow-container span {
    transform: rotate(180deg);
}

.sub-container,
.add-container {
    background-color: rgb(23, 33, 92);
    color: white;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
