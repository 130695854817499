.search-card {
    width: 250px;
    display: grid;
    place-items: center;
    place-content: center;
    height: 200px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 20px;
    background-color: white;
    margin: 20px 20px;
}

.icons-container {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 159, 159);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s ease;

}

.icons-container:hover {
    background-color: rgb(203, 45, 45);
}



.search-card .icons-container svg {
    font-size: 40px;
    filter: brightness(0) invert(1);

}

.search-card {
    font-size: 16px;
    font-weight: bold;
}