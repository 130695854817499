.feature-card-child {
    width: 320px;
    height: 100%;
    min-height: 180px;
    border-radius: 20px;
    overflow: hidden;
    transition: all 2s ease;
}

/* .rounded {
    border-radius: 20px;
} */

.featured-top {
    padding: 20px;
}

.featured-bottom {
    padding: 20px;
}



.overlay-content {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
    background-position: center;
    background-repeat: no-repeat;
}

.featured-card {
    background-color: rgb(64, 130, 107);
    color: white;
    text-transform: uppercase;
    padding: 4px 20px;
    font-size: 0.8em;
    border-radius: 6px;
}



.package-name {
    font-size: 1.2em;
    font-weight: 700;
    color: white;
    width: 100%;
    white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
}

.package-loaction {
    color: white;
    font-size: 0.8em;
    letter-spacing: 2px;
}

.package-loaction::before {
    font-family: "Material Icons" !important;
    content: "\e0c8" !important;
    color: red;
    font-size: 19px;
    margin: auto 0px;
}

.package-price {
    color: white;
    font-weight: 500;
    font-size: 0.9em;
}

.price {
    color: rgb(214, 80, 80);
    font-weight: bold;
}

.review-star span {
    color: goldenrod;
    font-size: 16px;
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.review-star-half span {
    color: goldenrod;
    font-size: 16px;
}

.feature-card-child:hover {
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.feature-card-child:hover .featured-bottom {
    bottom: -10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* height: 200px; */
    background-color: white;
    transition: all 0.4s ease;

}

.feature-card-child:hover .package-price,
.feature-card-child:hover .package-loaction {
    color: grey;
}

.feature-card-child:hover .package-name {
    color: black;
}

#package-wishlist-btn .added {
    background-color: var(--primary-color);
    cursor: pointer;
    padding: 6px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 6px;
}

#package-wishlist-btn .not-added {
    cursor: pointer;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important;
    background-position: center;
    background-repeat: no-repeat;
    /* width: 30px;
    height: 30px; */
    padding: 6px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 6px;
}



@media screen and (max-width: 768px) {
    .feature-card-child {
        width: 280px;

    }
.package-name {
    font-size: 1em;
}

.featured-top,
.featured-bottom {
    padding: 8px;
}
}