.custom-select {
    width: 270px;
    height: 60px;
    background-color: white;
    cursor: pointer;
}
.close-click {
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.select-icon-container span {
    font-size: 40px;
    margin: auto;
    color: rgb(206, 73, 73)
}

.location-select-option-container {
    max-width: 270px;
    width: 100%;
    max-height: 200px;
        bottom: 12px;
    overflow-y: scroll;
    z-index: 3;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.location-select-option-container .list-group-item {
    padding: 0 !important;
    border: none !important;
    font-size: 14px;
    height: 40px;
    line-height: 22px;
    padding-left: 10px !important;
}

.location-select-option-container li {
    cursor: pointer;
}

.location-select-option-container li:hover {
    background-color: #cccccc;
}



.close-click.is-open .arrow-container span {
    transform: rotate(180deg);
}
@media screen and (max-width: 550px) {
    .location-select-option-container {
        bottom: 40px;
    }
}