.navbar-nav .nav-item {
    margin: 0px 20px;
    padding: 15px 0px;
}
nav {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.nav-item {
    border-bottom: 3px solid transparent;
    padding: 10px 0px;
}


.nav-item:hover {
    border-bottom: 3px solid var(--primary-color);
}

.navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 18px;

}

.navbar-nav .dropdown-toogle::after {
    font-family: "Material Icons" !important;
    content: "\e5cf" !important;
}

.nav-link span {
    font-size: 14px;
}

.navbar-nav a,
.navbar-nav a:hover {
    color: #757783;

}

.navbar-nav a:visited {
    color: #757783;
}
.navbar-nav .dropdown-menu {
    border: none;
    min-width: 250px;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 2s ease-in;
    animation-name: scale-in-ver-top;
        animation-duration: 0.6s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
        /* animation: shutter-in-top 1s ease 0s 1 normal none; */
}

@keyframes scale-in-ver-top {
    0% {
        transform: scaleY(0);
            transform-origin: 100% 0%;
            opacity: 1;
    }

    100% {
        transform: scaleY(1);
            transform-origin: 100% 0%;
        opacity: 1;
    }
}

.navbar-nav .dropdown-menu .dropdown-item {
    background: 0 0;
    position: relative;
    text-align: left;
    border-bottom: 1px solid #ebe6de;
    padding: 5px 0;
    display: block;
    line-height: 1.4;
    padding: 8px 0;
    font-size: 15px;
    text-transform: capitalize;
    position: relative;
    color: grey;
}

.nav-search-container,
.profile-container {
    background-color: #faf5ee;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
}

.search-container span,
.profile-container span {
    color: black;
    font-weight: 200;
    font-size: 28px;
}

.navbar-top-container {
    background-color: #313041;
}

.social-links-container i {
    margin-left: 20px;
    color: white;

}

.navbar-top-container p {
    margin-bottom: 0px;
    color: white;
}

.contact-top .bi {
    color: rgb(218, 82, 82);
}
.profile-collapse-container {
    position: absolute;
    bottom: -110px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    min-width: 130px;
    display: none;
}

.profile-collapse-container li a {
    color: #757783;
    text-decoration: none;
}

.profile-collapse-container li {
    margin: 9px 0px;
    border-bottom: 1px solid #ebe6de;
    text-align: left;
}

.profile-container:hover .profile-collapse-container {
    display: block;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


}

.dashboard-navbar {
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 5;
}