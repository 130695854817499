.contact-side-container {
    border: 1px solid rgb(206, 206, 206);
    padding: 40px 20px;
    max-width: 300px;
    border-radius: 20px;
}

.contact-side-container .contact-details {
    color: var(--text-color);
    border-top: 1px solid rgb(206, 206, 206);
    padding-top: 20px;
}