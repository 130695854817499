.offer-child {
    border: 1px solid rgb(206, 206, 206);
    border-radius: 10px;
    padding: 50px;
    width: 250px;
    margin: 10px auto;
        margin-left: 20px;
}

.offer-heading {
    width: 300px;
}

.offer-child .icon-container {
    background-color: #faf5ee;
    padding: 30px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    position: relative;
    /* overflow: hidden; */
}

.offer-child .icon-container span {
    font-size: 70px;
    color: #da5252;

}

.offer-child .icon-text {
    font-weight: bolder;
    font-size: 20px;
    margin-top: 30px;
    text-align: center;
    line-height: 1.2;
    /* font-family: 'DM Sans', sans-serif; */
}

.offer-child .icon-container::after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    /* display: none; */
    border-radius: 50%;
    z-index: -1;
    background-color: #faf5ee;
    /* animation: pulse-leave 0.35s linear 0s 1 normal forwards; */

}

.offer-child:hover .icon-container::after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    display: block;
    z-index: 1;
    transform: scale(1);
    background-color: var(--primary-color);
    animation: pulse 0.35s linear 0s 1 normal forwards;
}


@keyframes pulse {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulse-leave {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}


.offer-child:hover .icon-container span {
    color: #faf5ee;
    z-index: 10;
}
@media screen and (max-width: 768px) {
    .offer-child {
        margin: 10px auto;
    }
}